import React, { useRef, useEffect, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import deleteIcon from 'assets/img/icons/remove.png';
import { useMapList } from './helper';
import rickshawPin from 'assets/img/rickshaw.png';
import hotelPin from 'assets/img/hotel.png';
import carPin from 'assets/img/car.png';
import bikePin from 'assets/img/bike.png';

const libraries = ['places', 'drawing'];

// eslint-disable-next-line react/prop-types
const MapLocation = () => {
  const { mapList } = useMapList();
  const mapRef = useRef();
  const drawingManagerRef = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDL28BwaoZ9H-Grr1YadDA5qDv3109JsYY',
    libraries
  });

  const [center, setCenter] = useState({ lat: 30.3753, lng: 69.3451 });
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    if (mapList['driver'] && mapList['driver'].length > 0) {
      const parsedCoordinates = mapList['driver'].map(coord => ({
        lat: Number(coord['latitude']),
        lng: Number(coord['longitude'])
      }));

      setCenter(parsedCoordinates[0]);

      const markerBounds = new window.google.maps.LatLngBounds();
      parsedCoordinates.forEach(coord =>
        markerBounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng))
      );
      setBounds(markerBounds);
    }
  }, [mapList]);

  const containerStyle = {
    width: '60%',
    height: '60vh'
  };

  const deleteIconStyle = {
    cursor: 'pointer',
    backgroundImage: `url(${deleteIcon})`,
    height: '24px',
    width: '24px',
    marginTop: '5px',
    backgroundColor: '#fff',
    position: 'absolute',
    top: '2px',
    left: '28%',
    zIndex: 99999
  };

  const onLoadMap = map => {
    mapRef.current = map;
    if (bounds) {
      map.fitBounds(bounds);
    }
  };

  const pinLogo = id => {
    switch (id) {
      case 1:
        return bikePin;
      case 12:
        return rickshawPin;
      default:
        return carPin;
    }
  };

  const darkMapStyle = [
    {
      featureType: 'all',
      elementType: 'geometry.fill',
      stylers: [{ color: '#2c2c2c' }]
    }
  ];

  return isLoaded ? (
    <div className="map-container" style={{ position: 'relative' }}>
      {drawingManagerRef.current && (
        <div title="Delete shape" style={deleteIconStyle}></div>
      )}
      <GoogleMap
        zoom={8}
        center={center}
        onLoad={onLoadMap}
        bounds={bounds}
        mapContainerStyle={containerStyle}
        onTilesLoaded={() => setCenter(null)}
        options={{ styles: darkMapStyle }}
      >
        {mapList['driver'] &&
          mapList['driver']?.map((coord, markerIndex) => (
            <Marker
              key={markerIndex}
              icon={{
                url: pinLogo(coord.vehicleDetails.vehicleService[0].id),
                scaledSize: new window.google.maps.Size(30, 30)
              }}
              position={{
                lat: Number(coord['latitude']),
                lng: Number(coord['longitude'])
              }}
            />
          ))}
        {mapList['property_owners'] &&
          mapList['property_owners']?.map((coord, markerIndex) => (
            <Marker
              key={markerIndex}
              icon={{
                url: hotelPin,
                scaledSize: new window.google.maps.Size(30, 30)
              }}
              position={{
                lat: Number(coord['property_latitude']),
                lng: Number(coord['property_longitude'])
              }}
            />
          ))}
      </GoogleMap>
    </div>
  ) : null;
};

export default MapLocation;
