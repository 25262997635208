import React, { useRef, useEffect, useState } from 'react';
import {
  DrawingManager,
  GoogleMap,
  Polygon,
  useJsApiLoader
} from '@react-google-maps/api';
import deleteIcon from 'assets/img/icons/remove.png';

const libraries = ['places', 'drawing'];

// eslint-disable-next-line react/prop-types
const MapComponent = ({ setCoordinates, initialCoordinates }) => {
  const mapRef = useRef();
  const drawingManagerRef = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDRDA3pULpIFh_OUaqjmsrTuPdKioLQNXE',
    libraries
  });

  const defaultCenter = {
    lat: 30,
    lng: 69
  };

  const [polygons, setPolygons] = useState([]);
  const [center, setCenter] = useState(defaultCenter);

  useEffect(() => {
    if (
      initialCoordinates &&
      // eslint-disable-next-line react/prop-types
      initialCoordinates.length > 0 &&
      typeof initialCoordinates == 'object'
    ) {
      // Process and set the initial coordinates when they are provided
      // eslint-disable-next-line react/prop-types
      const parsedCoordinates = initialCoordinates.map(coord => ({
        lat: coord[0],
        lng: coord[1]
      }));
      setPolygons([parsedCoordinates]);
      setCenter(parsedCoordinates[0]); // Set the center to the first coordinate
      setCoordinates(formatCoordinates(parsedCoordinates));
    }
  }, [initialCoordinates, setCoordinates]);

  const formatCoordinates = coords =>
    coords
      .map(coord => `(${coord.lat.toFixed(4)}, ${coord.lng.toFixed(4)})`)
      .join(',');

  const containerStyle = {
    width: '50%',
    height: '500px'
  };

  const deleteIconStyle = {
    cursor: 'pointer',
    backgroundImage: `url(${deleteIcon})`,
    height: '24px',
    width: '24px',
    marginTop: '5px',
    backgroundColor: '#fff',
    position: 'absolute',
    top: '2px',
    left: '28%',
    zIndex: 99999
  };

  const polygonOptions = {
    fillOpacity: 0.3,
    fillColor: '#ff0000',
    strokeColor: '#ff0000',
    strokeWeight: 2,
    draggable: true,
    editable: true
  };

  const drawingManagerOptions = {
    polygonOptions: polygonOptions,
    drawingControl: true,
    drawingControlOptions: {
      position: window.google?.maps?.ControlPosition?.TOP_CENTER,
      drawingModes: [window.google?.maps?.drawing?.OverlayType?.POLYGON]
    }
  };

  const onLoadMap = map => {
    mapRef.current = map;
  };

  const onLoadDrawingManager = drawingManager => {
    drawingManagerRef.current = drawingManager;
  };

  const onOverlayComplete = $overlayEvent => {
    drawingManagerRef.current.setDrawingMode(null);
    if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
      const newPolygon = $overlayEvent.overlay
        .getPath()
        .getArray()
        .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

      // start and end point should be same for valid geojson
      const startPoint = newPolygon[0];
      newPolygon.push(startPoint);
      $overlayEvent.overlay?.setMap(null);
      const addedCoordinates = {};
      const formattedCoordinates = newPolygon
        .filter(coord => {
          const key = `${coord.lat.toFixed(4)}, ${coord.lng.toFixed(4)}`;
          if (!addedCoordinates[key]) {
            addedCoordinates[key] = true;
            return true;
          }
          return false;
        })
        .map(coord => `(${coord.lat.toFixed(4)}, ${coord.lng.toFixed(4)})`)
        .join(',');
      setCoordinates(formattedCoordinates);
      setPolygons([...polygons, newPolygon]);
    }
  };

  const onDeleteDrawing = () => {
    setPolygons([]);
  };

  return isLoaded ? (
    <div className="map-container" style={{ position: 'relative' }}>
      {drawingManagerRef.current && (
        <div
          onClick={() => onDeleteDrawing()}
          title="Delete shape"
          style={deleteIconStyle}
        ></div>
      )}
      <GoogleMap
        zoom={4}
        center={center}
        onLoad={onLoadMap}
        mapContainerStyle={containerStyle}
        onTilesLoaded={() => setCenter(null)}
      >
        <DrawingManager
          onLoad={onLoadDrawingManager}
          onOverlayComplete={onOverlayComplete}
          options={drawingManagerOptions}
        />
        {polygons.map((iterator, index) => (
          <Polygon key={index} options={polygonOptions} paths={iterator} />
        ))}
      </GoogleMap>
    </div>
  ) : null;
};

export default MapComponent;
