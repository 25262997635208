import React, { useRef } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import deleteIcon from 'assets/img/icons/remove.png';
import { usePropertyRequestLocation } from './helper';
import hotelPin from 'assets/img/hotel.png';

const libraries = ['places', 'drawing'];

const PropertyRequestLocation = () => {
  const { location } = usePropertyRequestLocation();
  const mapRef = useRef();
  const drawingManagerRef = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDRDA3pULpIFh_OUaqjmsrTuPdKioLQNXE',
    libraries
  });

  const containerStyle = {
    width: '60%',
    height: '60vh'
  };

  const deleteIconStyle = {
    cursor: 'pointer',
    backgroundImage: `url(${deleteIcon})`,
    height: '24px',
    width: '24px',
    marginTop: '5px',
    backgroundColor: '#fff',
    position: 'absolute',
    top: '2px',
    left: '28%',
    zIndex: 99999
  };

  const onLoadMap = map => {
    mapRef.current = map;
  };

  const darkMapStyle = [
    {
      featureType: 'all',
      elementType: 'geometry.fill',
      stylers: [{ color: '#2c2c2c' }]
    }
  ];

  let centerLat =
    (Number(location?.property_owner_detail?.latitude) +
      Number(location?.user_detail?.latitude)) /
    2;
  let centerLng =
    (Number(location?.property_owner_detail?.longitude) +
      Number(location?.user_detail?.longitude)) /
    2;

  return isLoaded ? (
    <div
      className="map-container"
      style={{ position: 'relative', display: 'flex' }}
    >
      {drawingManagerRef.current && (
        <div title="Delete shape" style={deleteIconStyle}></div>
      )}
      <GoogleMap
        zoom={8}
        center={{
          lat: centerLat,
          lng: centerLng
        }}
        onLoad={onLoadMap}
        mapContainerStyle={containerStyle}
        options={{ styles: darkMapStyle }}
      >
        <Marker
          icon={{
            url: hotelPin,
            scaledSize: new window.google.maps.Size(30, 30)
          }}
          position={{
            lat: Number(location?.property_owner_detail?.latitude),
            lng: Number(location?.property_owner_detail?.longitude)
          }}
        />
        <Marker
          position={{
            lat: Number(location?.user_detail?.latitude),
            lng: Number(location?.user_detail?.longitude)
          }}
        />
      </GoogleMap>
    </div>
  ) : null;
};

export default PropertyRequestLocation;
